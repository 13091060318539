import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '../css/map-drop-point.css';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Chart from 'chart.js/auto';
import { IconContext } from "react-icons";
import { BsLayersHalf } from "react-icons/bs";
import RecommendationNavBarComponent from "../../components/RecommendationNavBarComponent";
import { RiFullscreenFill, RiFullscreenExitFill } from "react-icons/ri";
import ExportExcel from "../../utils/ExportExcel";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const URL = process.env.REACT_APP_URL_SERVER_BACK;

const _ = require('lodash');
let URL_points = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				"type": "LineString",
				"coordinates": [
				]
			}
		}
	]
};

let generated_tiles = false;
let generated_station = false;
let generated_building = false;

let point_geojson = {
	'type': 'FeatureCollection',
	'features': [
		{
			'type': 'Feature',
			'geometry': {
				'type': 'Point',
				'coordinates': [9.248677891126201, 47.41466848384309]
			}
		}
	]
};

class HideElementsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideNavs: false,
			mapa: this.props.map,
		}
	}

	hideComponent() {
		let newHideNavs = !this.state.hideNavs;
		this.setState({ hideNavs: newHideNavs })
		this.props.updateField("hideNavs", newHideNavs);
	}

	render() {

		const { hideNavs } = this.state;

		return (
			<div>
				{hideNavs ? <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenExitFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</> : <>
					<div className='map-close-navs'>
						<div className='map-close-navs-inner' onClick={() => this.hideComponent()}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
								<div>
									<RiFullscreenFill />
								</div>
							</IconContext.Provider>
						</div>
					</div>
				</>}
			</div>
		)
	}
}

//Layer Tyles Selection - Side Menu
class LayerTylesComponent extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			showOptions: false,
			mapa: this.props.map,
		}
		this.hideComponent = this.hideComponent.bind(this);
		this.handleTyleEvent = this.handleTyleEvent.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map })
		}
	}

	hideComponent(name) {
		this.setState({ showOptions: !this.state.showOptions });
	}

	handleTyleEvent(event) {
		switch (event) {
			case "default":
				this.props.handleTyle("mapbox://styles/mapbox/light-v10");
				break;
			case "outdoors":
				this.props.handleTyle("mapbox://styles/mapbox/outdoors-v11");
				break;
			case "satellite":
				this.props.handleTyle("mapbox://styles/mapbox/satellite-streets-v11");
				break;
			case "streets":
				this.props.handleTyle("mapbox://styles/mapbox/streets-v11");
				break;
			case "dark":
				this.props.handleTyle("mapbox://styles/mapbox/dark-v10");
				break;
			default:
				// eslint-disable-next-line no-unused-expressions
				null;
		}
		this.props.handleCoord(this.state.mapa.getCenter(), this.state.mapa.getZoom());
	}

	render() {
		const { showOptions } = this.state;

		return (
			<div>
				{showOptions ? <>
					<div id='layer-options' className='map-layer-options-tyles'>
						<div className='map-layer-option-dark' onClick={() => this.handleTyleEvent("dark")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Dark.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Dark</p>
						</div>
						<div className='map-layer-option-streets' onClick={() => this.handleTyleEvent("streets")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Streets.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Streets</p>
						</div>
						<div className='map-layer-option-satellite' onClick={() => this.handleTyleEvent("satellite")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Satellite.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Satellite</p>
						</div>
						<div className='map-layer-option-outdoors' onClick={() => this.handleTyleEvent("outdoors")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/Outdoors.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Outdoors</p>
						</div>
						<div className='map-layer-option-default' onClick={() => this.handleTyleEvent("default")}>
							<IconContext.Provider value={{ color: "#5B5B5B", size: '1.6em' }}>
								<div>
									<img src={require('../../assets/img/White.png')} alt="Default tyle" className='img-tyle' />
								</div>
							</IconContext.Provider>
							<p className='label'>Default</p>
						</div>
					</div>
				</> : <></>}
				<div className='map-layer-tyles'>
					<div className='map-layer-tyles-inner' onClick={() => this.hideComponent("showOptions")}>
						<IconContext.Provider value={{ color: "#5B5B5B", size: '1.8em' }}>
							<div>
								<BsLayersHalf />
							</div>
						</IconContext.Provider>
					</div>
				</div>
			</div>
		)
	}
}

class BtnsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mapa: this.props.map,
			generatedTyles: false,
			loading: false,

			switch_state: false,
			current_step: 1,
			tileId: 0,
			name_company: '',
			selected_station: this.props.selected_station,
			selected_building: this.props.selected_building,
			fileExcel: null
		}
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps) {
		if (this.props.map !== prevProps.map) {
			this.setState({ mapa: this.props.map });
		}
		if (this.props.coord !== prevProps.coord) {
			this.setState({ coord: this.props.coord });
		}
		if (this.props.selected_station !== prevProps.selected_station) {
			console.log(this.props.selected_station, prevProps.selected_station)
			this.setState({ selected_station: this.props.selected_station })
		}
		if (this.props.selected_building !== prevProps.selected_building) {
			console.log(this.props.selected_building, prevProps.selected_building)
			this.setState({ selected_building: this.props.selected_building })
		}
	}

	handleStep1() {
		this.props.map.setLayoutProperty('point', 'visibility', 'none');

		fetch(URL + 'tile_od_agg/coordinates_to_tiles', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				"coordinates": [
					[point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1]]
				]
			})
		})
			.then((res) => res.json())
			.then((json) => {
				this.setState({ tileId: json.features[0].properties.tileId })
				generated_tiles = true;

				let tyle_corner = {
					center: [point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1]],
					zoom: 12,
					pitch: 0,
					bearing: 0
				}

				this.state.mapa.flyTo({
					...tyle_corner, // Fly to the selected target
					duration: 2000, // Animate over 2 seconds
					essential: false // This animation is considered essential with
					//respect to prefers-reduced-motion
				})
			}).then(() => {
				fetch(URL + 'potential_routes/routes_template/stations', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ means_of_transport: 'Zug' })
				})
					.then((res) => res.json())
					.then((json) => {
						this.state.mapa.getSource('data-circles-stations').setData(json);

					}).then(() => this.setState({ current_step: 2 }))
			})

	}

	handleStep2() {
		generated_station = true;
		this.setState({ current_step: 3 })
	}

	handleCompanyName(event) {
		this.setState({ name_company: event.target.value })
	}

	handleDropPoint() {
		//TODO
	}

	handleStep1Excel() {
		this.setState({ current_step: 2 });
		fetch(URL + 'tile_od_agg/coordinates_to_tiles', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				"coordinates": [
					[point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1]]
				]
			})
		})
			.then((res) => res.json())
			.then((json) => {
				this.setState({ tileId: json.features[0].properties.tileId })
				generated_tiles = true;
				this.props.map.setLayoutProperty('swiss-1km-tile', 'visibility', 'none');
				this.props.map.setLayoutProperty('outline-swiss-1km-tile', 'visibility', 'none');
				this.props.map.setLayoutProperty('point', 'visibility', 'none');

				this.props.map.getSource('data-swiss-buldings-tile').setData(URL + 'areas_to_focus/return_buildings/' + json.features[0].properties.tileId + '/all');

			})
	}

	handleStep2Excel() {
		generated_building = true;
		this.setState({ current_step: 3 })
	}

	handleStep3Excel() {
		this.setState({ current_step: 4 })
	}

	handleExcelImport(){

	}


	handleTypeUpload() {
		console.log(this.state.switch_state)

		let currentState = !this.state.switch_state

		console.log(currentState)


		this.setState({ switch_state: currentState })
		this.reset();

		if (!currentState) {

		} else {
			let tyle_corner = {
				center: [point_geojson.features[0].geometry.coordinates[0], point_geojson.features[0].geometry.coordinates[1]],
				zoom: 12,
				pitch: 0,
				bearing: 0
			}

			this.state.mapa.flyTo({
				...tyle_corner, // Fly to the selected target
				duration: 2000, // Animate over 2 seconds
				essential: false // This animation is considered essential with
				//respect to prefers-reduced-motion
			})
		}
	}

	reset() {
		this.props.updateFields({ selected_station: '', current_circle_id: '' });
		this.setState({ current_step: 1, name_company: '', generatedTyles: false, tileId: 0, fileExcel: null	});
		generated_tiles = false;
		generated_station = false;
		generated_building = false;

		point_geojson = {
			'type': 'FeatureCollection',
			'features': [
				{
					'type': 'Feature',
					'geometry': {
						'type': 'Point',
						'coordinates': [9.248677891126201, 47.41466848384309]
					}
				}
			]
		};

		this.state.mapa.getSource('data-circles-stations').setData(URL_points);
		this.props.map.setLayoutProperty('swiss-1km-tile', 'visibility', 'visible');
		this.props.map.setLayoutProperty('outline-swiss-1km-tile', 'visibility', 'visible');
		this.props.map.setLayoutProperty('point', 'visibility', 'visible');

		this.state.mapa.getSource('data-swiss-1km-tile').setData({
			"type": "FeatureCollection",
			"features": [
				{
					"type": "Feature",
					"geometry": {
						"type": "Polygon",
						"coordinates": [
							[
								[
									9.240824,
									47.413758
								],
								[
									9.240824,
									47.422542
								],
								[
									9.254378,
									47.422542
								],
								[
									9.254378,
									47.413758
								],
								[
									9.240824,
									47.413758
								]
							]
						]
					},
					"properties": {
						"tileId": 48913525,
						"PLZ": 9200,
						"name": "Gossau SG",
						"color": "#FF006E"
					}
				}
			]
		})
		this.state.mapa.getSource('point').setData({
			'type': 'FeatureCollection',
			'features': [
				{
					'type': 'Feature',
					'geometry': {
						'type': 'Point',
						'coordinates': [9.248677891126201, 47.41466848384309]
					}
				}
			]
		})
	}

	getExcelTemplate() {
		var all = [];
		all.push({
			"Adresse": '',
			"PLZ": '',
			"Ort": '',
			"Land": '',
			"Num_Employees": '',
		})

		return all;
	}

	handleFileExcel(event){
		 console.log(event.target.files[0])
		this.setState({fileExcel: event.target.files[0]})
	}


	render() {
		const { loading } = this.state;
		const { generatedTyles } = this.state;

		const ExcelExportData = this.getExcelTemplate();

		if (loading) { // if your component doesn't have to wait for an async action, remove this block 
			return (
				<div className="bg-loader">
					<div className="pos-center">
						<div className="loader"></div>
					</div>
				</div>
			)
		}
		else {
			return (
				<div>
					<div className="switch-change-div" style={{ top: '0px', left: '260px', height: '100px' }}>
						<div className="switch-change-div-inner" style={{ height: '100px' }}>
							<div className="column-33">
								<p className="label">Drop a Point</p>
							</div>
							<div className="column-33">
								<label className="switch">
									<input type="checkbox" onChange={this.handleTypeUpload.bind(this)} value={this.state.switch_state} />
									<span className="slider round"></span>
								</label>
							</div>
							<div className="column-33">
								<p className="label">Excel Import</p>
							</div>
						</div>
					</div>
					<div className="map-btn-drop-point">
						<div className="map-btn-drop-point-inner">
							<h1>Upload Data <br /> {this.state.switch_state ? 'Excel Import' : 'Drop a Point'}</h1>
							{
								(!this.state.switch_state) ?
									<>
										{
											this.state.current_step === 1
												?
												<>
													<p className="label">Place the circle on the desired coordinates (Employer Building). Click on <i>Next Step</i> when you have selected the tile to analyze.</p>
													<div className="row">
														<div>
															<Form onClick={this.handleStep1.bind(this)}>
																<Button className="generate-btn" variant="info" disabled={(generatedTyles)}>Next Step: Station</Button>
															</Form>
														</div>
													</div>
												</>
												:
												<>
													{
														this.state.current_step === 2 ?
															<><p className="label">Select the nearest train station that the employees will use to commute. Click on <i>Next Step</i> when you have selected the train station.</p>
																<div className="row">
																	<div>
																		<Form onClick={this.handleStep2.bind(this)}>
																			<Button className="generate-btn" variant="info" disabled={(this.props.selected_station === '')}>Next Step: Company</Button>
																		</Form>
																	</div>
																</div>
															</>
															:
															<><p className="label" style={{ marginBottom: '5px' }}>Write the name of the company:</p>
																<input style={{ width: '90%' }} type="text" value={this.state.name_company} onChange={this.handleCompanyName.bind(this)} />
																<p className="label" style={{ marginBottom: '5px' }}>Click on <i>Compute</i> when all data is correct.</p>
																<div className="row">
																	<div>
																		<Form onClick={this.handleDropPoint.bind(this)}>
																			<Button className="generate-btn" variant="info" disabled={(this.state.name_company === '')}>Compute</Button>
																		</Form>
																	</div>
																</div>
															</>
													}
												</>
										}
									</>
									:
									<>
										{
											this.state.current_step === 1
												?
												<>
													<p className="label">Place the circle on the desired coordinates (Employer Tile). Click on <i>Next Step</i> when you have selected the tile to analyze.</p>
													<div className="row">
														<div>
															<Form onClick={this.handleStep1Excel.bind(this)}>
																<Button className="generate-btn" variant="info" disabled={(generatedTyles)}>Next Step: Building</Button>
															</Form>
														</div>
													</div>
												</>
												:
												<>
													{
														this.state.current_step === 2 ?
															<><p className="label">Select the company bulding associated with the HR Data. Click on <i>Next Step</i> when you have selected the correct building.</p>
																<div className="row">
																	<div>
																		<Form onClick={this.handleStep2Excel.bind(this)}>
																			<Button className="generate-btn" variant="info" disabled={(this.props.selected_building === '')}>Next Step: Company</Button>
																		</Form>
																	</div>
																</div>
															</>
															:
															<>
																{
																	this.state.current_step === 3 ?
																		<><p className="label" style={{ marginBottom: '5px' }}>Write the name of the company:</p>
																			<input style={{ width: '90%' }} type="text" value={this.state.name_company} onChange={this.handleCompanyName.bind(this)} />
																			<p className="label" style={{ marginBottom: '5px' }}>Click on <i>Next Step</i> when all data is correct.</p>
																			<div className="row">
																				<div>
																					<Form onClick={this.handleStep3Excel.bind(this)}>
																						<Button className="generate-btn" variant="info" disabled={(this.state.name_company === '')}>Next Step: HR Data Excel</Button>
																					</Form>
																				</div>
																			</div>
																		</>
																		:
																		<>
																			<p className="label">Please upload the HR Data for the company selected. See the provided template for the data structure required. Click on <i>Compute</i> when you have successfully uploaded the file.</p>
																			<div className="row">
																				<div className="column-1-eq">
																					<ExportExcel excelData={ExcelExportData} fileName={'HR Data Template Excel'} name={'Excel Template'}/>
																				</div>
																				<div className="column-2-eq">
																				<Form onClick={this.handleExcelImport.bind(this)}>
																			<Button className="generate-btn" variant="info" disabled={(!this.state.fileExcel)}>Compute</Button>
																		</Form>
																				</div>
																			</div>
																				</>
																}
															</>
													}
												</>
										}
									</>
							}


						</div>

						{
							this.state.current_step === 4 && this.state.switch_state 
							?
							<>
							<div className="map-btn-drop-point" style={{ top: '180px' }}>
						<div className="map-btn-drop-point-inner" style={{ height: '30px' }}>
						<input type="file" onChange={this.handleFileExcel.bind(this)} style={{ width: '100%' }}></input>
							</div></div>
							</>
							:<></>
						}
					</div>
				</div>
			)
		}
	}
}

class MappUpload_Data extends React.Component {

	// Set up states for updating map 
	constructor(props) {
		super(props);
		this.state = {
			lng: 9.248677891126201,
			lat: 47.41466848384309,
			zoom: 12,
			mapita: 9,
			tileid_exploration: 0,
			tileid_name: 0,
			tyle: 'mapbox://styles/mapbox/satellite-streets-v11',
			hideNavs: false,
			selected_station: '',
			current_circle_id: '',
			selected_building: '',
			current_building_id: ''
		}
	}

	//Update several fields
	updateFields(list) {
		this.setState(list);
	}

	//Update map on component update
	updateField(card, value) {
		this.setState({
			...this.state,
			[card]: value
		});
	}

	//Update style
	handleTyle(value) {
		this.setState({ tyle: value }, this.componentDidMount);
	}

	//Update coordinates
	handleCoord(center, zoom) {
		const { lng, lat } = center;
		this.setState({ zoom: zoom, lat: lat, lng: lng })
	}

	componentDidMount() {
		// Set up map 
		const map = new mapboxgl.Map({
			container: this.mapContainer,
			style: this.state.tyle,
			center: [this.state.lng, this.state.lat],
			zoom: this.state.zoom,
		});

		map.on('load', () => {

			// Add zoom and rotation controls to the map.
			map.addControl(new mapboxgl.NavigationControl({ showCompass: false, showZoom: false }), 'top-left');
			map.addControl(new MapboxGeocoder({ accessToken: mapboxgl.accessToken, mapboxgl: mapboxgl, marker: false }), 'top-left');

			//Circles - Stations
			map.addSource('data-circles-stations', {
				'type': 'geojson',
				'data': URL_points,
				'generateId': true

			});
			map.addLayer({
				'id': 'data-circles-stations',
				'type': 'circle',
				'source': 'data-circles-stations',
				'paint': {
					'circle-radius': {
						"stops": [
							// zoom is 5 -> circle radius will be 2px
							[5, 2],
							// zoom is 11 -> circle radius will be 8px
							[11, 8]
						]

					},
					"circle-stroke-width": 1,
					"circle-stroke-color": [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						'#3aeb34', // if selected true, paint in pink
						'#eb34b1' // else paint
					],
					'circle-color': [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						'#3aeb34', // if selected true, paint in pink
						'#eb34b1' // else paint
					],
					"circle-opacity": 0.7,
				},
			});

			map.addSource('data-swiss-1km-tile', {
				'type': 'geojson',
				'data': {
					"type": "FeatureCollection",
					"features": [
						{
							"type": "Feature",
							"geometry": {
								"type": "Polygon",
								"coordinates": [
									[
										[
											9.240824,
											47.413758
										],
										[
											9.240824,
											47.422542
										],
										[
											9.254378,
											47.422542
										],
										[
											9.254378,
											47.413758
										],
										[
											9.240824,
											47.413758
										]
									]
								]
							},
							"properties": {
								"tileId": 48913525,
								"PLZ": 9200,
								"name": "Gossau SG",
								"color": "#FF006E"
							}
						}
					]
				},
				'generateId': true
			});

			//Tyle visualization - 1km
			map.addLayer({
				'id': 'swiss-1km-tile',
				'type': 'fill',
				'source': 'data-swiss-1km-tile',
				'layout': {},
				'paint': {
					'fill-color': '#51aea1',
					'fill-opacity': 0.2
				}
			});

			map.addLayer({
				'id': 'outline-swiss-1km-tile',
				'type': 'line',
				'source': 'data-swiss-1km-tile',
				'layout': {},
				'paint': {
					'line-color': '#51aea1',
					'line-width': 1
				}
			});

			// Add circle 
			map.addSource('point', {
				'type': 'geojson',
				'data': point_geojson
			});

			map.addLayer({
				'id': 'point',
				'type': 'circle',
				'source': 'point',
				'paint': {
					'circle-radius': 10,
					'circle-color': '#e32a8d' // blue color
				}
			});

			//Add shapes to the map (Buildings within a tyle)
			map.addSource('data-swiss-buldings-tile', {
				'type': 'geojson',
				'data': URL_points,
				'generateId': true
			});

			map.addLayer({
				'id': 'data-swiss-buldings-tile',
				'type': 'fill',
				'source': 'data-swiss-buldings-tile',
				'paint': {
					'fill-color': [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						'#eb34b1', // else paint
						['get', 'color']
					],
					'fill-opacity': 0.3
				}
			});

			// Add a black outline around the polygon
			map.addLayer({
				'id': 'outline-data-swiss-buldings-tile',
				'type': 'line',
				'source': 'data-swiss-buldings-tile',
				'paint': {
					'line-color': [
						'case',
						['boolean', ['feature-state', 'clicked'], false],
						'#eb34b1', // else paint
						['get', 'color']
					],
					'line-width': 1
				}
			});
		});

		this.setState({
			mapita: map,
			zoom: map.getZoom(),
			center: map.getCenter()
		});

		map.on('click', 'data-circles-stations', (e) => {

			if (!generated_station) {
				map.getCanvas().style.cursor = 'pointer';
				let circleID = e.features[0].id;

				let current_array = this.state.selected_station;

				let elem_id = e.features[0].properties.stop_id;

				if (elem_id !== current_array) {
					let elems = e.features[0].properties
					elems['circle_map_id'] = circleID

					if (this.state.current_circle_id !== '') {
						map.setFeatureState({
							source: 'data-circles-stations',
							id: this.state.current_circle_id,
						}, {
							clicked: false
						});
					}

					map.setFeatureState({
						source: 'data-circles-stations',
						id: circleID,
					}, {
						clicked: true
					});

					this.updateFields({ selected_station: elem_id, current_circle_id: circleID })

				} else {

					map.setFeatureState({
						source: 'data-circles-stations',
						id: circleID,
					}, {
						clicked: false
					});

					this.updateFields({ selected_station: '', current_circle_id: '' })
				}
			}

		});

		map.on('click', 'data-swiss-buldings-tile', (e) => {

			if (!generated_building) {
				let buildingID = e.features[0].id;

				let current_array = this.state.selected_building;
				let elem_id = e.features[0].properties.id;

				console.log(current_array, elem_id)

				if (elem_id !== current_array) {
					let elems = e.features[0].properties
					elems['circle_map_id'] = buildingID

					if (this.state.current_building_id !== '') {
						map.setFeatureState({
							source: 'data-swiss-buldings-tile',
							id: this.state.current_building_id,
						}, {
							clicked: false
						});
					}

					map.setFeatureState({
						source: 'data-swiss-buldings-tile',
						id: buildingID,
					}, {
						clicked: true
					});

					this.updateFields({ selected_building: elem_id, current_building_id: buildingID })

				} else {

					map.setFeatureState({
						source: 'data-swiss-buldings-tile',
						id: buildingID,
					}, {
						clicked: false
					});

					this.updateFields({ selected_building: '', current_building_id: '' })
				}
			}

		});

		const popupHover = new mapboxgl.Popup({
			closeButton: false,
			closeOnClick: false
		});

		map.on('mouseenter', 'data-circles-stations', (e) => {
			map.getCanvas().style.cursor = 'pointer';
			let stop_name = e.features[0].properties['Stop name']

			let html = '<h4 style="margin-block-start:0px; margin-block-end:0px;">Stop Name</h4><p style="margin-block-start:0px; margin-block-end: 0em;">' + stop_name + '</p>';
			popupHover
				.setLngLat(e.lngLat)
				.setHTML(html).addTo(map);
		});

		// Change it back to a pointer when it leaves.
		map.on('mouseleave', 'data-circles-stations', () => {
			map.getCanvas().style.cursor = '';
			popupHover.remove();
		});


		//On click move the point
		map.on('click', (event) => {
			const coords = event.lngLat;
			if (!generated_tiles) {
				// Print the coordinates of where the point had
				// finished being dragged to on the map.

				map.getCanvas().style.cursor = '';

				// Unbind mouse/touch events
				map.off('mousemove', onMove);
				map.off('touchmove', onMove);

				let html = '<b>Is the point placed on the entrance of the building?</b><br/> If not, please place it as close as possible to it.';
			popupHover
				.setLngLat(event.lngLat)
				.setHTML(html).addTo(map);

				point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
				map.getSource('point').setData(point_geojson);

				fetch(URL + 'tile_od_agg/coordinates_to_tiles', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						"coordinates": [
							[coords.lng, coords.lat]
						]
					})
				})
					.then((res) => res.json())
					.then((json) => {
						map.getSource('data-swiss-1km-tile').setData(json);
					})


			}
		});


		// When the cursor enters a feature in
		// the point layer, prepare for dragging.
		map.on('mouseenter', 'point', () => {
			map.setPaintProperty('point', 'circle-color', '#9e2e6a');
			map.getCanvas().style.cursor = 'move';
		});

		map.on('mouseleave', 'point', () => {
			map.setPaintProperty('point', 'circle-color', '#9e2e6a');
			map.getCanvas().style.cursor = '';
		});

		function onMove(e) {
			const coords = e.lngLat;
			// Set a UI indicator for dragging.
			map.getCanvas().style.cursor = 'grabbing';

			// Update the Point feature in `geojson` coordinates
			// and call setData to the source layer `point` on it.
			point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
			map.getSource('point').setData(point_geojson);
		}

		function onUp(e) {
			const coords = e.lngLat;
			// Print the coordinates of where the point had
			// finished being dragged to on the map.

			map.getCanvas().style.cursor = '';

			// Unbind mouse/touch events
			map.off('mousemove', onMove);
			map.off('touchmove', onMove);

			point_geojson.features[0].geometry.coordinates = [coords.lng, coords.lat];
			map.getSource('point').setData(point_geojson);
		}

		map.on('mousedown', 'point', (e) => {
			// Prevent the default map drag behavior.
			e.preventDefault();

			map.getCanvas().style.cursor = 'grab';

			map.on('mousemove', onMove);
			map.once('mouseup', onUp);
		});

		map.on('touchstart', 'point', (e) => {
			if (e.points.length !== 1) return;

			// Prevent the default map drag behavior.
			e.preventDefault();

			map.on('touchmove', onMove);
			map.once('touchend', onUp);
		});

		// Fullscreen map 
		//map.addControl(new mapboxgl.FullscreenControl());
	}

	render() {
		const { hideNavs } = this.state;

		return (
			<div>
				<div ref={el => this.mapContainer = el} style={{ width: '100%', height: '100vh' }}>
				</div>
				<RecommendationNavBarComponent />
				<HideElementsComponent map={this.state.mapita} updateField={(card, field, value) => this.updateField(card, field, value)} />
				<div style={{ display: hideNavs ? 'none' : 'block' }}>
					<BtnsComponent map={this.state.mapita} updateFields={(list) => this.updateFields(list)} coord={this.state.coord} selected_station={this.state.selected_station} selected_building={this.state.selected_building} />
				</div>
				<LayerTylesComponent map={this.state.mapita} tyle={this.state.tyle} handleTyle={(value) => this.handleTyle(value)} handleCoord={(value1, value2) => this.handleCoord(value1, value2)} />
			</div>
		)
	}
}

export default MappUpload_Data; 